import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useMediaQuery, useTheme } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItemButton';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Tab, TabList, TabPanel, Tabs } from 'react-web-tabs';
import 'react-web-tabs/dist/react-web-tabs.css';
import { Row } from 'reactstrap';
import BacktestAreaChart from './BacktestAreaChart';
import BacktestBarChart from './BacktestBarChart';
import LiveAreaChart from './LiveAreaChart';
import MethodologyText from './MethodologyText';
import RecentForecastTable from './RecentForecastTable';
import StatsTables from './StatsTables';

function HomeTabs(props) {
  const [tabType, setTabType] = useState('backtest');
  const [selectedTab, setSelectedTab] = useState(null);
  const [openCollapseId, setOpenCollapseId] = useState(null);
  const [portFolioList, setPortFolioList] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState(null);
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const fetchPortFolioData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/portfolios/?skip=0&limit=10`
      );
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching portfolio data:', error);
      return [];
    }
  };

  const fetchAssetsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/assets?skip=0&limit=10`
      );
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching assets data:', error);
      return [];
    }
  };

  // Fetch data only once on mount
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const [portfolios, assets] = await Promise.all([
        fetchPortFolioData(),
        fetchAssetsData(),
      ]);

      if (portfolios.length > 0 && assets.length > 0) {
        const listOfCategories = portfolios.map((category) => {
          const subcategories = assets.filter(
            (subcategory) => subcategory.portfolio_id === category.id
          );
          return {
            ...category,
            subcategories,
          };
        });

        setPortFolioList(listOfCategories);

        // Set default selection
        // if (
        //   listOfCategories.length > 0 &&
        //   listOfCategories[0].subcategories.length > 0
        // ) {
        const firstAsset = portfolios[0];
        setSelectedAssets(firstAsset);
        setSelectedTab(firstAsset.id);
        setOpenCollapseId(listOfCategories[0].id);
        // }
      }
      setLoading(false);
    };

    loadData();
  }, []);

  // Handle collapsible click
  const handleClick = useCallback((portfolioId) => {
    setOpenCollapseId((prevId) =>
      prevId === portfolioId ? null : portfolioId
    );
  }, []);

  // Update selected assets based on portfolio collapse
  // useEffect(() => {
  //   if (openCollapseId !== null) {
  //     const portfolio = portFolioList.find((p) => p.id === openCollapseId);
  //     if (portfolio && portfolio.subcategories.length > 0) {
  //       const firstAsset = portfolio.subcategories[0];
  //       setSelectedAssets(firstAsset);
  //       setSelectedTab(firstAsset.id);
  //     }
  //   }
  // }, [openCollapseId, portFolioList]);

  const override = `
    display: block;
    margin: 0 auto;
  `;

  const handleTab = (portFolioList) => {
    return (
      <>
        {portFolioList.length > 0 && (
          <Row className="toggle-buttons">
            {['Live Forecasts', 'backtest', 'methodology'].map((type) => (
              <span
                key={type}
                onClick={() => setTabType(type)}
                className={
                  tabType === type
                    ? 'toggle-button-mae-selected'
                    : 'toggle-button-mae'
                }
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </span>
            ))}
          </Row>
        )}
      </>
    );
  };

  return (
    <div className="mt-4">
      {!isSmallDevice && handleTab(portFolioList)}
      <p>
        {loading && <ClipLoader css={override} size={45} color={'#005687'} />}
      </p>

      <Tabs selectedTab={selectedTab?.toString()} vertical id="flex-wrapper">
        <TabList
          id="tablist"
          style={{
            position: 'sticky',
            top: '0',
            alignSelf: 'flex-start',
            marginRight: '4rem',
          }}
          className="tab_list_css"
        >
          <List
            sx={{
              width: 260,
              padding: '0px !important',
              bgcolor: 'transparent',
              '&:hover': { background: 'transparent' },
            }}
            component="nav"
            disablePadding
          >
            {portFolioList.map((item) => (
              <ListItem
                key={item.id}
                disableRipple
                sx={{
                  padding: '7px',
                  paddingBottom: '0px',
                  bgcolor: 'transparent',
                  flexDirection: 'column',
                  position: 'relative',
                  '&:hover': { background: 'transparent' },
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'stretch',
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  {item?.visible ? (
                    <>
                      <Tab
                        tabFor={item.id.toString()}
                        onClick={() => {
                          if (!item.disabled) {
                            setSelectedAssets(item);
                            setSelectedTab(item.id);
                            handleClick(item.id);
                          }
                        }}
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          cursor: item.disabled ? 'not-allowed' : 'pointer',
                          opacity: item.disabled ? 0.5 : 1,
                        }}
                      >
                        <img
                          className="tab-image"
                          width="100%"
                          src={`${process.env.REACT_APP_BASE_URL}${
                            item.cover_image_url || item?.cover_image
                          }`}
                          alt="Card cap"
                          style={{
                            filter: item.disabled ? 'grayscale(100%)' : 'none',
                          }}
                        />
                        <span
                          className={
                            item.id.toString() === selectedTab
                              ? 'toggle-mae-selected'
                              : 'toggle-mae'
                          }
                        >
                          {item?.name}
                        </span>
                        <div></div>
                      </Tab>
                      <span
                        style={{
                          display: 'inline-block',
                          // margin: '1rem',
                          position: 'absolute',
                          right: '-35px',
                          top: '10px',
                        }}
                      >
                        {openCollapseId === item.id ? (
                          <ExpandLess
                            onClick={() => handleClick(item.id)}
                            sx={{ color: '#005687', fontSize: '1.8rem' }}
                          />
                        ) : (
                          <ExpandMore
                            onClick={() => handleClick(item.id)}
                            sx={{ color: '#005687', fontSize: '1.8rem' }}
                          />
                        )}
                      </span>
                    </>
                  ) : null}
                </div>
                {item?.visible
                  ? item.subcategories.map(
                      (doc) =>
                        doc.visible && (
                          <Collapse
                            in={openCollapseId === item.id}
                            timeout="auto"
                            unmountOnExit
                            key={doc.id}
                            style={{ width: '90%', marginLeft: 'auto' }}
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                disableRipple
                                sx={{
                                  padding: 0,
                                  '&:hover': { background: 'transparent' },
                                }}
                              >
                                <Tab
                                  tabFor={doc.id.toString()}
                                  style={{
                                    width: '100%',

                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                  onClick={() => {
                                    if (doc) {
                                      setSelectedAssets(doc);
                                      setSelectedTab(doc.id);
                                    }
                                  }}
                                >
                                  <img
                                    className="tab-image"
                                    width="100%"
                                    src={`${process.env.REACT_APP_BASE_URL}${doc.logo}`}
                                    alt={doc.name}
                                  />
                                  <span
                                    className={
                                      doc.id.toString() === selectedTab
                                        ? 'toggle-mae-selected'
                                        : 'toggle-mae'
                                    }
                                  >
                                    {doc.name}
                                  </span>
                                  <div></div>
                                </Tab>
                              </ListItem>
                            </List>
                          </Collapse>
                        )
                    )
                  : null}
              </ListItem>
            ))}
          </List>
        </TabList>

        {isSmallDevice && handleTab(portFolioList)}

        {selectedAssets && (
          <TabPanel tabId={selectedAssets.id.toString()}>
            {tabType === 'backtest' ? (
              <>
                <BacktestAreaChart
                  theme={props.theme}
                  assetName={selectedAssets?.name}
                  id={selectedAssets?.id}
                />
                <BacktestBarChart
                  metricName="Sharpe"
                  data={selectedAssets}
                  theme={props.theme}
                />
                <h3 className="color-ucla mt-4">
                  Evaluation Metrics of MQE Algorithm on {selectedAssets?.name}
                </h3>
                <StatsTables data={selectedAssets} />
                <h3 className="color-ucla">Performance of Recent Forecasts</h3>
                <RecentForecastTable
                  id={selectedAssets?.id}
                  forecastTerm={props.forecastTerm}
                />
              </>
            ) : tabType === 'Live Forecasts' ? (
              <>
                <h3
                  class="color-ucla"
                  style={{
                    textAlign: 'center',
                    marginBottom: '10px',
                    marginTop: '15px',
                  }}
                >
                  Past Forecast Performance of Trading Strategy
                </h3>

                <LiveAreaChart
                  theme={props.theme}
                  assetName={selectedAssets?.name}
                  id={selectedAssets?.id}
                  data={selectedAssets}
                />
                <h3 className="color-ucla">Performance of Recent Forecasts</h3>
                <RecentForecastTable
                  id={selectedAssets?.id}
                  forecastTerm={props.forecastTerm}
                />
              </>
            ) : (
              <MethodologyText data={selectedAssets} />
            )}
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
}

export default HomeTabs;
